import { ReactNode } from "react";
import ContentWrapper from "../ContentWrapper";
import styles from "./index.module.css";

const Header = ({ children }: { children?: ReactNode }) => {
  return (
    <>
      <ContentWrapper className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.left}>
            <a href="/">
              <img
                src="https://img.mtgso.cn/web_images/logo_0918.png"
                style={{ display: "block" }}
              />
            </a>
            {children}
          </div>
          <div className={styles.right}></div>
        </div>
      </ContentWrapper>

      <div style={{ height: 60 }} />
    </>
  );
};

export default Header;
