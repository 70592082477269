export function conv_rarity(s) {
  const dict = {
    common: "普通",
    uncommon: "非普通",
    rare: "稀有",
    mythic: "秘稀",
  };
  return dict[s];
}

export const legalityList = [
  { key: "standard", value: "标准" },
  { key: "pioneer", value: "先驱" },
  { key: "modern", value: "摩登" },
  { key: "legacy", value: "薪传" },
  { key: "vintage", value: "特选" },
  { key: "duel", value: "法禁" },
  { key: "commander", value: "官禁多人" },
];
