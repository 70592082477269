import styles from "./index.module.css";
import { Input, Pagination } from "@arco-design/web-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import ContentWrapper from "../../components/ContentWrapper";
import Header from "../../components/Header";

const axios = require("axios").default;

const InputSearch = Input.Search;

const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState(searchParams.get("q") || "");
  const [pagination, setPagination] = useState({
    size: 60,
    page: 1,
    total: 0,
  });
  const navigate = useNavigate();
  const [cards, setCards] = useState<any>([]);

  const fetch = () => {
    axios
      .get("https://wxapi.mtgso.cn/opensearch/demo/demo_search.php", {
        params: {
          printed_name: inputValue,
          page: pagination.page,
        },
      })
      .then((res: any) => {
        setCards(res.data[1]);
        setPagination((prev) => ({
          ...prev,
          total: res.data[0],
        }));
      });
  };

  useEffect(() => {
    fetch();
  }, [pagination.page]);

  return (
    <>
      <Header>
        <InputSearch
          allowClear
          placeholder="搜索卡牌名"
          style={{ width: 300 }}
          value={inputValue}
          onChange={(s) => {
            setInputValue(s);
          }}
          onPressEnter={() => {
            navigate({
              pathname: "/search",
              search: `?${createSearchParams({ q: inputValue })}`,
            });
            fetch();
          }}
        />
      </Header>

      <ContentWrapper>
        <div className={styles.cards}>
          {cards.map((card: any) => {
            return (
              <a href={`/card?id=${card.id}`}>
                <div key={card.id} className={styles.card}>
                  <img src={card.imgurl_large} />
                </div>
              </a>
            );
          })}
        </div>
      </ContentWrapper>

      <ContentWrapper>
        <div className={styles.paginationContainer}>
          <Pagination
            pageSize={pagination.size}
            current={pagination.page}
            total={pagination.total}
            onChange={(pageNumber) => {
              setPagination((prev) => ({ ...prev, page: pageNumber }));
            }}
          />
        </div>
      </ContentWrapper>
    </>
  );
};

export default Search;
