import { useEffect, useState } from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import ContentWrapper from "../../components/ContentWrapper";
import Header from "../../components/Header";
import styles from "./index.module.css";
import { Typography, Radio, Descriptions } from "@arco-design/web-react";
import { conv_rarity, legalityList } from "./utils";
import classNames from "classnames";

const axios = require("axios").default;

const Card = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardID, setCardID] = useState(searchParams.get("id") || "");
  const [cardData, setCardData] = useState<any>({});
  const navigate = useNavigate();

  const fetch = () => {
    axios
      .post("/api", {
        api: "1.0",
        method: "mtg-get-card",
        version: "1.0",
        payload: {
          id: cardID,
        },
      })
      .then((res: any) => {
        setCardData(res.data.payload);
      });
  };

  useEffect(() => {
    fetch();
  }, [cardID]);
  return (
    <>
      <Header></Header>
      <ContentWrapper>
        <div className={styles.main}>
          <div className={styles.left}>
            <img
              src={cardData?.["card-images"]?.[0] || ""}
              className={styles.cardImage}
            />
          </div>
          <div className={styles.mid}>
            <Typography.Title heading={4}>
              {cardData?.["card-info"]?.["name-trans"]}
            </Typography.Title>
            <Typography.Title heading={6} style={{ marginTop: -13 }}>
              {cardData?.["card-info"]?.["name"]}
            </Typography.Title>
            <Typography.Title heading={6}>语言</Typography.Title>
            <Radio.Group
              options={(cardData?.["all-languages"] || []).map((item: any) => ({
                value: item.id,
                label: item.lang,
              }))}
              size="mini"
              type="button"
              value={cardData?.["card-info"]?.id}
              onChange={(value) => {
                navigate({
                  pathname: "/card",
                  search: `?${createSearchParams({ id: value })}`,
                });
                setCardID(value);
              }}
            ></Radio.Group>
            <Typography.Title heading={6}>叙述</Typography.Title>
            <Typography.Paragraph>
              {cardData?.["card-faces"]?.[0]?.["text-oracle"]}
            </Typography.Paragraph>
            <Typography.Title heading={6}>信息</Typography.Title>
            <Descriptions
              border
              size="mini"
              data={[
                {
                  label: "稀有度",
                  value: conv_rarity(cardData?.["card-info"]?.["rarity"]),
                },
                {
                  label: "费用",
                  value: cardData?.["card-faces"]?.[0]?.["mana-cost"],
                },
                {
                  label: "类别",
                  value: cardData?.["card-info"]?.["type-line"],
                },
                {
                  label: "总费用",
                  value: cardData?.["card-info"]?.["cmc"],
                },
                {
                  label: "系列",
                  value: cardData?.["card-info"]?.["set-id"],
                },
                {
                  label: "画家",
                  value: cardData?.["card-faces"]?.[0]?.["artist"],
                },
              ]}
            />
            <Typography.Title heading={6}>赛制</Typography.Title>
            <div className={styles.legalityContainer}>
              {legalityList.map((format) => {
                return (
                  <div
                    className={classNames(
                      styles.format,
                      styles[
                        cardData?.["card-info"]?.["legalities"]?.[format.key] ||
                          "not_legal"
                      ]
                    )}
                  >
                    {format.value}
                  </div>
                );
              })}
            </div>
            {(cardData?.["rulings"] || []).length > 0 && (
              <>
                <Typography.Title heading={6}>规则</Typography.Title>

                {(cardData?.["rulings"] || []).map((ruling: any) => {
                  return (
                    <>
                      <Typography.Paragraph>
                        {ruling.comment}
                        <span style={{ color: "var(--color-text-2)" }}>
                          &nbsp;({ruling.published})
                        </span>
                      </Typography.Paragraph>
                    </>
                  );
                })}
              </>
            )}
          </div>
          <div className={styles.right}>
            <Typography.Title heading={6} style={{ alignSelf: "center" }}>
              全部系列
            </Typography.Title>
            {(cardData?.["full-all-sets"] || []).map((item: any) => {
              return (
                <div
                  key={item.id}
                  className={classNames({
                    [styles.setActive]:
                      item["set-id"] === cardData?.["card-info"]?.["set-id"] &&
                      item["set-num"] === cardData?.["card-info"]?.["set-num"],
                    [styles.set]: true,
                  })}
                  onClick={() => {
                    navigate({
                      pathname: "/card",
                      search: `?${createSearchParams({ id: item.id })}`,
                    });
                    setCardID(item.id);
                  }}
                >
                  <div>{item["name-cn"] || item.name}</div>
                  <div className={styles.setSubText}>
                    #{item["set-num"]} {item["set-id"]}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};

export default Card;
