import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Index from "./pages/index";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "@arco-design/theme-mtgso/css/arco.css";
import Search from "./pages/search";
import Card from "./pages/card";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/search" element={<Search />} />
        <Route path="/card" element={<Card />} />
      </Routes>
    </Router>
  );
}

export default App;
