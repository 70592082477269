import { Layout, Link, Input } from "@arco-design/web-react";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import styles from "./index.module.css";

const Header = Layout.Header;
const Footer = Layout.Footer;
const Content = Layout.Content;
const InputSearch = Input.Search;

const Index = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  return (
    <Layout className={styles.main}>
      <Header>Header</Header>
      <Content>
        <div className={styles.content}>
          <img
            src="https://img.mtgso.cn/web_images/logo_0918.png"
            className={styles.logo}
          />
          <InputSearch
            size="large"
            allowClear
            placeholder="搜索卡牌名"
            style={{ width: 500, height: 44 }}
            className={styles.search}
            value={inputValue}
            onChange={(s) => {
              setInputValue(s);
            }}
            onPressEnter={() => {
              navigate({
                pathname: "/search",
                search: `?${createSearchParams({ q: inputValue })}`,
              });
            }}
          />
          <Link href="/advance">高级搜索</Link>
        </div>
      </Content>
      <Footer>Footer</Footer>
    </Layout>
  );
};
export default Index;
