import { ReactNode } from "react";
import styles from "./index.module.css";
import classNames from 'classnames';

const ContentWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <>
      <div className={classNames(styles.contentContainer, className)}>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default ContentWrapper;
